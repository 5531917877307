import React, { useState } from "react"

const materials = {
  P: "Plastic",
  W: "Wood",
  M: "Metal",
  G: "Glass",
  C: "Concrete",
  E: "Electronics",
  F: "Fabric",
  D: "Digital",
  N: "Mixed",
  U: "Unusual",
}

const quantities = {
  0: "Prototype",
  1: "Limited Edition",
  2: "Small Run",
  3: "Medium Run",
  4: "Large Run",
  5: "Mass Production",
  6: "Large Scale Production",
  7: "Industrial Scale",
  8: "Mass Market",
  9: "Global Scale",
}

const BudmenIdGenerator = () => {
  const [playPerform, setPlayPerform] = useState("P")
  const [playPerformIntensity, setPlayPerformIntensity] = useState("5")
  const [inspireInstruct, setInspireInstruct] = useState("I")
  const [inspireInstructIntensity, setInspireInstructIntensity] = useState("5")
  const [material, setMaterial] = useState("")
  const [quantity, setQuantity] = useState("")
  const [year, setYear] = useState("")
  const [month, setMonth] = useState("")

  const [id, setId] = useState("")

  const generateId = () => {
    const newId = `B-${playPerform}${playPerformIntensity}${inspireInstruct}${inspireInstructIntensity}${material}${quantity}-${year}${month}`
    setId(newId)
  }

  return (
    <div>
      <select onChange={e => setPlayPerform(e.target.value)}>
        <option value="P">Play</option>
        <option value="F">Perform</option>
      </select>
      <input
        type="number"
        defaultValue={5}
        min="0"
        max="9"
        onChange={e => setPlayPerformIntensity(e.target.value)}
      />
      <select onChange={e => setInspireInstruct(e.target.value)}>
        <option value="I">Inspire</option>
        <option value="T">Instruct</option>
      </select>
      <input
        type="number"
        min="0"
        defaultValue={5}
        max="9"
        onChange={e => setInspireInstructIntensity(e.target.value)}
      />
      <select onChange={e => setMaterial(e.target.value)}>
        <option value="">Material</option>
        {Object.keys(materials).map(key => (
          <option value={key}>{materials[key]}</option>
        ))}
      </select>
      <select onChange={e => setQuantity(e.target.value)}>
        <option value="">Production Type</option>
        {Object.keys(quantities).map(key => (
          <option value={key}>{quantities[key]}</option>
        ))}
      </select>
      <input
        type="text"
        onChange={e => setYear(e.target.value)}
        placeholder="YY"
      />
      <input
        type="text"
        onChange={e => setMonth(e.target.value)}
        placeholder="MM"
      />
      <button
        className="snipcart-add-item disabled:opacity-90 disabled:hover:opacity-100 disabled:cursor-not-allowed flex-shrink-0 text-white mb-4 bg-purple-500 border-0 py-2 px-4 focus:outline-none hover:bg-purple-600 rounded font-bold tracking-widest text-xs uppercase md:mt-4 mt-0"
        onClick={generateId}
      >
        Generate ID
      </button>
      {id && <p>Generated ID: {id}</p>}
    </div>
  )
}

export default BudmenIdGenerator
