import * as React from "react"
// import Layout from "../components/layout"
import Seo from "../components/seo"
import { FiAlertTriangle } from "react-icons/fi"
import BudmenIdGenerator from "../components/IDGenerator"

const ScoutMerch = () => {
  return (
    <div>
      <Seo title="IDGen-Beta" />
      <div
        className="bg-indigo-100 rounded-lg flex items-center gap-2 justify-center py-5 px-6 mb-4 text-xs text-indigo-700"
        role="alert"
      >
        <FiAlertTriangle />
        This is a beta feature for internal use.
      </div>

      <div className="flex flex-col justify-center pt-4">
        <h1 className="text-2xl my-0 font-bold tracking-widest uppercase mt-4 mb-6 text-center">
          Project ID Generator
        </h1>
        {/* Product 4 */}
        <section className="lg:flex md:flex-row mb-8 flex-col justify-center items-center text-center mx-auto w-11/12">
          <BudmenIdGenerator />
        </section>
      </div>
    </div>
  )
}
export default ScoutMerch
